<template>
  <div class="products-sort">
<!--    <span class="products-sort-title">Sort by</span>-->
    <div class="products-actions">
      <b-dropdown right>
        <template #button-content>
          <span>
            {{ sortDefaultItem }}
          </span>
        </template>
        <b-dropdown-item
          v-for="item of sortOptions"
          :key="item.value"
          @click="selectHandler(item)"
        >
          {{ item.text }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ProductsSortSelect',
    props: {
      sortOptions: {
        type: Array,
        required: true,
        default: () => [],
      },
    },
    data: () => {
      return {
        sortDefaultItem: 'Please select some item',
      };
    },
    watch: {
      '$route.query': {
        immediate: true,
        handler({ sort }) {
          sort && this.changeSelectDefaultTitle(sort);
        },
      },
    },
    methods: {
      changeSelectDefaultTitle(item) {
        if (!item) {
          this.sortDefaultItem = 'Please select some item';

          return;
        };

        this.sortDefaultItem = this.sortOptions.find(el => el.value === item).text;
      },
      selectHandler(item) {
        this.$emit('selectHandler', item.value || null);
      },
    },
  }
</script>

<style lang='scss' scoped>
  .products-sort {
    display: flex;
    align-items: center;

    .products-sort-title {
      @media (max-width: 554px) {
        display: none;
      }
    }

    .products-actions {

      &::v-deep {
      .dropdown-toggle::after {
        content: none;
      }

      .btn-group {
        display: flex;
        justify-content: space-between;
        @media (max-width: 959px) {
          width: 335px;
          flex-basis: 335px;
        }
      }

      .btn-secondary {
        display: flex;
        appearance: none;
        background: url('../../assets/icons/icon-sortArow.svg') no-repeat right
          0.75rem center/8px 10px;
        border-color: #e1e1f1;
        background-color: #fff;
        font-size: 15px;
        color: #333953;
        max-width: 139px;

        @media (max-width: 959px) {
          max-width: 100%;
        }

        &:active{
          background-color: #fff;
          color: #333953;
          box-shadow: none;
          outline: none;
          border-color: #e1e1f1;
        }

        &:focus{
          box-shadow: none;
          outline: none;
          border-color: #e1e1f1;
        }

        &:active:focus{
          box-shadow: none;
        }
      }

      .dropdown-item {
        &:active{
          background: none;
        }

        @media (max-width: 554px) {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .dropdown-menu {
        border-color: #e1e1f1;
        outline: none;

        @media (max-width: 554px) {
          max-width: 335px;
        }
      }
    }
  }

    span {
      margin-right: 10px;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>