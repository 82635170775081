<template>
  <div class="products-sort">
    <div class="products-actions">
      <b-dropdown right>
        <template #button-content>
          <span>
            {{ sortDefaultItem }}
          </span>
        </template>
        <b-dropdown-item
          v-for="item of sortOptions"
          :key="item.value"
          @click="selectHandler(item)"
        >
          {{ item.text }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CatalogSortDropdown',
    props: {
      sortOptions: {
        type: Array,
        required: true,
        default: () => [],
      },
    },
    data: () => {
      return {
        sortDefaultItem: 'Default sort',
      };
    },
    watch: {
      '$route.query': {
        immediate: true,
        handler({ sort }) {
          this.changeSelectDefaultTitle(sort);
        },
      },
    },
    methods: {
      changeSelectDefaultTitle(item) {
        if (!item) {
          this.sortDefaultItem = 'Default sort';

          return;
        }

        this.sortDefaultItem = this.sortOptions.find(el => el.value === item).text;
      },
      selectHandler(item) {
        this.$emit('selectHandler', item.value || null);
      },
    },
  }
</script>

<style lang='scss' scoped>
  .products-sort {
    display: flex;
    align-items: center;
    justify-content: right;
    @media (max-width: 800px) {
      width: 50%;
    }
    .products-sort-title {
      @media (max-width: 554px) {
        display: none;
      }
    }

    .products-actions {
      @media (max-width: 480px) {
        width: 162px;
      }
      @media (min-width: 481px) and (max-width: 800px) {
        width: 200px;
      }
      &::v-deep {
        .dropdown-toggle::after {
          content: none;
        }

        .btn-group {
          display: flex;
          justify-content: space-between;
          align-items: center;
          @media (max-width: 959px) {
            width: 335px;
            flex-basis: 335px;
          }
          @media (max-width: 800px) {
            width: auto;
          }
        }

        .btn-secondary {
          display: flex;
          appearance: none;
          align-items: center;
          background: url('../../../assets/icons/icon-sortArow.svg') no-repeat right 0.75rem center/8px 10px;
          border-color: #e1e1f1;
          background-color: #fff;
          color: #343434;
          width: 220px;
          height: 40px;
          border-radius: 5px;
          @media (max-width: 959px) {
            max-width: 100%;
          }

          &:active{
            background-color: #fff;
            color: #333953;
            box-shadow: none;
            outline: none;
            border-color: #e1e1f1;
          }

          &:focus{
            box-shadow: none;
            outline: none;
            border-color: #e1e1f1;
          }

          &:active:focus{
            box-shadow: none;
          }
        }

        .dropdown-item {
          &:active{
            background: none;
          }

          @media (max-width: 554px) {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .dropdown-menu {
          border-color: #e1e1f1;
          outline: none;

          @media (max-width: 554px) {
            max-width: 335px;
          }
        }
      }
    }

    span {
      margin-right: 10px;
      font-size: 15px;
      font-weight: 400;
      line-height: 18px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>