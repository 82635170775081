import { render, staticRenderFns } from "./catalog-product-list.component.vue?vue&type=template&id=5857ccc6&scoped=true"
import script from "./catalog-product-list.component.vue?vue&type=script&lang=js"
export * from "./catalog-product-list.component.vue?vue&type=script&lang=js"
import style0 from "./catalog-product-list.component.vue?vue&type=style&index=0&id=5857ccc6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5857ccc6",
  null
  
)

export default component.exports