<template>
  <div class="catalog-header">
    <div class="catalog-header--title">
      <div class="text-overflow">
        {{ selectedCategory }}
      </div>
      <div
        v-if="productNumber"
        class="catalog-header--count"
      >
        {{ productNumber }}
      </div>
    </div>
    <div class="catalog-header--sort">
      <catalog-sort-dropdown
        :sort-options="productSortItems"
        @selectHandler="sortSelect($event)"
      />
    </div>
  </div>
</template>

<script>
  import { productSortItems } from 'src/config/product-sort.config';
  import CatalogSortDropdown from '@/entities/catalog/components/catalog-sort-dropdown.component.vue';

  export default {
    name: 'CatalogHeaderComponent',
    components: { CatalogSortDropdown },
    props: {
      selectedCategory: {
        type: String,
        default: 'Home',
      },
      productNumber: {
        type: Number,
        default: 0,
      },
    },

    data() {
      return {
        productSortItems,
      };
    },

    computed: {},

    methods: {
      sortSelect(item) {
        this.$router.push({ query: item ? { sort: item } : null });
      },
    },

  };
</script>

<style lang="scss" scoped>
  .catalog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #F0F2F6;
    margin-top: 10px;
    padding-bottom: 5px;
    font-size: 26px;
    font-weight: 500;
    @media (max-width: 800px) {
      font-size: 18px;
      padding-bottom: 0;
      border-bottom: none;
    }
    &--title {
      color: #343434;
      display: flex;
      align-items: center;
      width: 100%;
    }

    &--count {
      margin-left: 0.35rem;
      color: #B6C0D3;
    }

    @media (max-width: 800px) {
      &--sort {
        display: none;
      }
    }

  }
</style>
