<template>
  <div class="catalog-product-list-wrapper">
    <div
      v-if="areProductsLoading || products.length < 1"
      class="catalog-product-list--loader"
    >
      <b-spinner v-if="areProductsLoading"/>
      <div
        v-if="!areProductsLoading && products.length < 1"
      >
        No items for selected category
      </div>
    </div>
    <div class="catalog-product-list">
      <catalog-product-list-item-component
        v-for="(product, i) in products"
        :key="i"
        :product="product"
        :is-in-wish-list="isInWishList(product)"
        :show-wish="showWish"
        @wish:set="manageWish"
      />
    </div>
  </div>
</template>

<script>
  import CatalogProductListItemComponent from '@/entities/catalog/components/catalog-product-list-item.component';

  export default {
    name: 'CatalogProductListComponent',
    components: { CatalogProductListItemComponent },
    props: {
      areProductsLoading: {
        type: Boolean,
        default: false,
      },
      products: {
        type: Array,
        default: () => [],
      },
      wishList: {
        type: Object,
        default: () => ({}),
      },
      showWish: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {};
    },

    computed: {},

    methods: {
      isInWishList(item) {
        return Object
          .keys(this.wishList)
          .includes(item.id);
      },
      manageWish(item) {
        this.$emit('wish:set', item);
      },
    },

  };
</script>

<style lang="scss" scoped>
  .catalog-product-list-wrapper {
    width: 100%;
    min-height: 300px;
    position: relative;
    .catalog-product-list--loader {
      z-index: 5;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 300px;
      position: absolute;
    }
    .catalog-product-list {
      display: grid;
      grid-template-columns: repeat(5, 2fr);
      gap: 0.5rem;
      @media (max-width: 1416px) {
        grid-template-columns: repeat(4, 2fr);
      }
      @media (max-width: 1183px) {
        grid-template-columns: repeat(3, 2fr);
      }
      @media (max-width: 960px) {
        grid-template-columns: repeat(2, 2fr);
      }
      @media (max-width: 800px) {
        grid-template-columns: repeat(3, 2fr);
      }
      @media (max-width: 650px) {
        grid-template-columns: repeat(2, auto);
        justify-content:space-between;
        > * {
          width: 200px;
        }
      }
      @media (max-width: 480px) {
        > * {
          width: 162px;
        }
      }
    }
  }
</style>
