<template>
  <div
    :class="{
      'catalog-nav-item': true,
      'text-overflow': isZeroLevel,
      'catalog-nav-item--first-level': !isZeroLevel,
    }"
    :title="item.title"
  >
    <div
      :class="{
        'catalog-nav-item--mobile': true,
        'catalog-nav-item--mobile--first-level': !isZeroLevel,
        'catalog-nav-item--mobile--with-image': imageSrc && isZeroLevel,
      }"
      @click="onClickImage"
    >
      <img
        v-if="imageSrc && isZeroLevel"
        :src="imageSrc"
      />
      <div
        v-else-if="!isZeroLevel"
      >
        {{ item.title }}
      </div>
    </div>
    <div>
      <router-link
        :to="item.link"
        :class="{
          'catalog-nav-item--link text-overflow': true,
          'catalog-nav-item--link': true,
        }"
      >
        <div>
          {{ item.title }}
        </div>
      </router-link>

      <router-link
        v-if="isZeroLevel"
        :to="item.link"
        :class="{
          'catalog-nav-item--link': true,
          'catalog-nav-item--link--mobile': true,
        }"
      >
        <div>
          {{ item.title }}
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CatalogNavItemComponent',
    props: {
      item: {
        type: Object,
        default: () => ({}),
      },
      isZeroLevel: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {};
    },

    computed: {
      imageSrc() {
        return this.item.icon;
      },
    },

    methods: {
      onClickImage() {
        this.$router.push(this.item.link);
      },
    },

  };
</script>

<style lang="scss" scoped>
  .catalog-nav-item {
    margin-bottom: 5px;
    &--link {
      @media (max-width: 800px) {
        display: none;
      }
      font-size: 15px;
      color: #343434;
      &:hover {
        color: $main-color;
      }

      &--mobile {
        display: none;
        @media (max-width: 800px) {
          display: inline;
          white-space: normal;
        }
      }
    }

    &--mobile {
      display: none;
      background-color: #BDBDBD;
      border-radius: 10px;
      aspect-ratio: 16 / 9;
      cursor: pointer;
      text-align: center;

      &--first-level {
        background-color: white;
        font-weight: 500;
        font-size: 13px;
        aspect-ratio: initial;
        min-height: 70px;
        border: 1px solid #E2E6EE;
        height: 100%;
        @media (min-width: 620px) {
          min-height: 80px;
        }
        @media (min-width: 720px) {
          min-height: 90px;
        }
      }

      &--with-image {
        background-color: initial;
        img {
          max-width: 100%;
          border-radius: 10px;
        }
      }
      img {
        height: 100%;
      }

      div {
        padding: 1rem;
      }
    }

    @media (max-width: 800px) {
      &--first-level {
        flex-shrink: 0;
        //width: 25%;
        width: 150px;
        .catalog-nav-item--mobile {
          //width: 33%;
          //aspect-ratio: initial;
        }
      }

      &--mobile {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &--link {
        font-weight: 500;
        font-size: 13px;
        text-align: center;
      }
    }

    @media (max-width: 500px) {
      &--first-level {
        width: 130px;
      }
    }

  }
</style>
