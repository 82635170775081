<template>
  <div
    class="catalog-product-list-item"
    @click="goToProduct"
  >
    <div
      class="catalog-product-list-item--image-wrapper"
    >
      <img
        v-if="image"
        class="swiper-lazy"
        :src="image"
        :class="{
          'sp-img-is-png': imgIsPng(image) && !showSpinner,
        }"
        @load="imageLoaded"
      >
      <span v-else>Image is not set</span>
      <div v-if="image && showSpinner" class="swiper-lazy-preloader"/>
      <div
        v-if="showWish"
        class="catalog-product-list-item--image-wrapper--wish"
        @click.stop="onClickWish"
      >
        <i
          :class="{
            'far fa-heart': true,
            'fas catalog-product-list-item--image-wrapper--wish--included': isInWishList,
          }"
        />
      </div>
    </div>
    <div class="catalog-product-list-item--title">
      {{ product.title }}
    </div>
    <div
      v-if="product.price || product.is_event_price_active"
      class="catalog-product-list-item--price"
    >
      <span class="item-price-value" v-if="product.is_event_price_active">
        {{ getFormattedCurrency(product.currency) || '$' }}{{ product.event_price }}
      </span>
      <span class="item-price-value" v-else>
        {{ getFormattedCurrency(product.currency) || '$' }}{{ product.price }}
      </span>

      <span v-if="product.original_price" class="item-price-value catalog-product-list-item--price--original">
        {{ getFormattedCurrency(product.currency) || '$' }}{{ product.original_price }}
      </span>
    </div>
  </div>
</template>

<script>
  import normalizeShopifyImg from '@/shared/helpers/normalize-shopify-img';
  import { getFormattedCurrency } from 'vimmi-web-utils/cjs/formatCurrency';

  const addedHeartImg = require('/src/assets/icons/added-to-wishlist-icon.svg');
  const addHeartImg = require('/src/assets/icons/add-to-wishlist-icon.svg');

  export default {
    name: 'CatalogProductListItemComponent',
    props: {
      product: {
        type: Object,
        default: () => ({}),
      },
      isInWishList: {
        type: Boolean,
        default: false,
      },
      showWish: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        showSpinner: true,
      };
    },

    computed: {
      heartImg() {
        return this.isInWishList
          ? addedHeartImg
          : addHeartImg;
      },
      image() {
        if (!this.product.poster) {
          return '';
        }

        return normalizeShopifyImg(this.product.poster, 220, 220);
      },
    },

    methods: {
      imgIsPng(src) {
        return !!src.includes('.png')
      },
      getFormattedCurrency,
      imageLoaded() {
        this.showSpinner = false;
      },
      onClickWish() {
        this.$emit('wish:set', this.product)
      },
      goToProduct() {
        this.$router.push(`/product/${this.product.slug}`);
      },
    },

  };
</script>

<style lang="scss" scoped>
  .catalog-product-list-item {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        .catalog-product-list-item--title {
          color: $main-color;
        }

        .catalog-product-list-item--image-wrapper {
          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(#000, 0.1);
          }
        }
      }
    }
    &--title {
      word-break: break-word;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      color: #343434;
      font-size: 15px;
    }

    &--price {
      font-size: 16px;
      font-weight: 500;

      &--original {
        margin-left: 2px;
        overflow: hidden;
        font-size: 12px;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;
          background: linear-gradient(
              to top right,
              transparent calc(50% - 2px),
              #e73e3f,
              transparent calc(50% + 1px)
          );
        }
      }
    }

    &--image-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      overflow: hidden;
      border-radius: 10px;
      $width: 220px;
      width: $width;
      height: $width;
      min-width: $width;
      min-height: $width;
      margin-bottom: 10px;

      @media (max-width: 480px) {
        $width-mobile: 162px;
        width: $width-mobile;
        height: $width-mobile;
        min-width: $width-mobile;
        min-height: $width-mobile;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        &:not(.sp-img-is-png) {
          background-image: url('../../../assets/logo/logo-shoprz.svg');
          background-position: 50% 50%;
          background-size: contain !important;
          background-color: rgba(#000, 0.1);
        }
      }
    }

    &--image-wrapper {
      &--wish {
        position: absolute;
        top: 5px;
        right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        padding: 5px;
        border-radius: 50%;
        cursor: pointer;
        z-index: 2;
        &--included {
          color: #F02F30;
        }
      }
    }
  }
</style>
