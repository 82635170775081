<template>
  <div class="catalog-breadcrumbs">
    <div
      v-for="(item, index) of breadcrumbs"
      :key="index"
      class="catalog-breadcrumbs--item"
      :title="item.title"
    >
      <router-link
        v-if="index !== (breadcrumbs.length - 1)"
        :to="item.link"
        :class="{
          'text-overflow': true,
          'catalog-breadcrumbs--link': true,
        }"
        @click.native="handleClick(item)"
      >
        {{ item.title }}
      </router-link>
      <div
        v-else
        class="catalog-breadcrumbs--title"
      >
        {{ item.title }}
      </div>
      <i
        v-if="index !== (breadcrumbs.length - 1)"
        class="fas fa-chevron-right catalog-breadcrumbs--divider"
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CatalogBreadcrumbsComponent',
    props: {
      breadcrumbs: {
        type: Array,
        default: () => [],
      },
    },

    data() {
      return {};
    },

    computed: {},

    methods: {
      handleClick() {
        document.documentElement.scrollTop = 0;
      },
    },

  };
</script>

<style lang="scss" scoped>
  .catalog-breadcrumbs {
    display: flex;
    align-items: center;
    font-size: 15px;
    @media (max-width: 800px) {
      overflow-x: auto;
      font-size: 12px;
    }
    &--item {
      display: flex;
      align-items: center;
    }
    i {
      margin: 0 0.5rem;
      display: block;
    }
    &--divider {
      color: #96A0B6;
      font-size: 0.6rem;
    }

    &--title {
      color: #96A0B6;
      white-space: nowrap;
    }
    &--link {
      display: inline-block;
      max-width: 200px;
      color: #343434;
      &:hover {
        color: $main-color;
      }
    }
  }
</style>
