import { amsClient } from 'src/service/ams';
import Config from '@/service/config';
import normalizeProductCategory from '@/shared/helpers/normalize-product-category';

export default {
  loadProducts(options) {
    const { category, offset = 0, sort = '' } = options || {};

    const sortQueryParam = sort ? `&sort=${sort}` : '';
    let url = null;
    const normalizedCategory = normalizeProductCategory(category);
    if (!normalizedCategory) {
      url = `/filter/shop_product/?filters=provider_id:${this.getPridId()}&count=20&start=${offset}${sortQueryParam}`;
    } else {
      url = `/shoprzapi/catalog/p/${normalizedCategory}/?start=${offset}&count=20${sortQueryParam}`
    }
    return amsClient.callAms(url);
  },
  getPridId() {
    return Config.get('provider_id') || 'paapc9jyxrdsvj88';
  },
}