<template>
  <div class="catalog-nav">
    <div
      class="catalog-nav--title"
      :class="{
        'catalog-nav--title--first-level': !isZeroLevel,
      }"
    >
      Catalog
    </div>
    <div
      :class="{
        'catalog-nav--list': true,
        'catalog-nav--list--zero-level': isZeroLevel,
      }"
    >
      <catalog-nav-item-component
        v-for="(item, i) in navigation"
        :key="i"
        :item="item"
        :is-zero-level="isZeroLevel"
      />
    </div>
  </div>
</template>
<script>
  import CatalogNavItemComponent from '@/entities/catalog/components/catalog-nav-item.component.vue';

  export default {
    name: 'CatalogNavComponent',
    components: { CatalogNavItemComponent },
    props: {
      navigation: {
        type: Array,
        default: () => [],
      },
      isZeroLevel: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>
<style lang="scss" scoped>
  .catalog-nav {

    &--list {
      @media (max-width: 800px) {
        display: flex;
        min-width: 100vw;
        max-width: fit-content;
        gap: 0.5rem;
        overflow-x: scroll;
        padding-right: 1.5rem;
        border-bottom: 1px solid #EFF2FA;
        padding-bottom: 0.5rem;
        margin-bottom: 1rem;
        align-items: stretch;
        margin-left: -20px;
        padding-left: 20px;
      }

      &--zero-level {
        @media (max-width: 800px) {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-gap: 0.5rem;
          align-items: flex-start;
          padding-bottom: 0;
          margin-left: -20px;
          padding-left: 20px;
          margin-bottom: 0;
          border-bottom: none;
        }
        @media (max-width: 620px) {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }

    &--title {
      font-weight: 500;
      margin-bottom: 10px;
      @media (max-width: 800px) {
        display: none;
      }
      &--first-level {
        @media (max-width: 800px) {
          display: none;
        }
      }
    }
    &--item {
      margin-bottom: 5px;
      &--link {
        font-size: 15px;
        color: #343434;
        &:hover {
          color: $main-color;
        }
      }
    }
  }
</style>
