const normalizeProductCategory = (category) => {
  let cat = (category || '');

  if (cat[0] === '/') {
    cat = cat.slice(1);
  }

  if (cat[cat.length - 1] === '/') {
    cat = cat.slice(0, -1);
  }

  return cat;
}

export default  normalizeProductCategory;