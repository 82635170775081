var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'catalog-nav-item': true,
    'text-overflow': _vm.isZeroLevel,
    'catalog-nav-item--first-level': !_vm.isZeroLevel,
  },attrs:{"title":_vm.item.title}},[_c('div',{class:{
      'catalog-nav-item--mobile': true,
      'catalog-nav-item--mobile--first-level': !_vm.isZeroLevel,
      'catalog-nav-item--mobile--with-image': _vm.imageSrc && _vm.isZeroLevel,
    },on:{"click":_vm.onClickImage}},[(_vm.imageSrc && _vm.isZeroLevel)?_c('img',{attrs:{"src":_vm.imageSrc}}):(!_vm.isZeroLevel)?_c('div',[_vm._v(" "+_vm._s(_vm.item.title)+" ")]):_vm._e()]),_c('div',[_c('router-link',{class:{
        'catalog-nav-item--link text-overflow': true,
        'catalog-nav-item--link': true,
      },attrs:{"to":_vm.item.link}},[_c('div',[_vm._v(" "+_vm._s(_vm.item.title)+" ")])]),(_vm.isZeroLevel)?_c('router-link',{class:{
        'catalog-nav-item--link': true,
        'catalog-nav-item--link--mobile': true,
      },attrs:{"to":_vm.item.link}},[_c('div',[_vm._v(" "+_vm._s(_vm.item.title)+" ")])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }