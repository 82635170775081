<template>
  <div class="page">
    <vue-headful
      :title="metadata.title"
      :description="metadata.description"
      :image="metadata.image"
      :url="metadata.url"
      :keywords="metadata.keyword"
      :head="metadata.head"
    />

    <div class="page-container">
      <div class="page-container-breadcrumbs">
        <catalog-breadcrumbs-component :breadcrumbs="finalBreadcrumbs"/>
        <catalog-header-component
          :selected-category="label"
          :product-number="currentProductNumber"
        />
      </div>
      <div class="page-container-content">
        <div class="page-catalog">
          <catalog-nav-component
            :navigation="finalNavigation"
            :is-zero-level="isZeroLevel"
          />
        </div>
        <div
          :class="{
            'page-content': true,
            'page-content--mobile': isZeroLevel,
          }"
        >
          <div
            class="page-content--header"
          >
            <div>

            </div>
            <catalog-sort-dropdown
              :sort-options="productSortItems"
              @selectHandler="sortSelect($event)"
            />
          </div>
          <catalog-product-list-component
            :products="productList"
            :wish-list="getList"
            :are-products-loading="areProductsLoading"
            :show-wish="isFullAuth"
            @wish:set="onSetWish"
          />
          <intersect-observer
            v-if="canLoadMoreProducts && !areProductsLoading"
            @intersect="() => loadProducts(selectedCategory, selectedSort)"
          />
<!--          <catalog-content-component />-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';

  import CatalogNavComponent from '@/entities/catalog/components/catalog-nav.component';
  import CatalogContentComponent from '@/entities/catalog/components/catalog-content.component';
  import normaliseMetaTags from '@/utils/normaliseMetaTags';
  import CatalogBreadcrumbsComponent from '@/entities/catalog/components/catalog-breadcrumbs.component';
  import CatalogHeaderComponent from '@/entities/catalog/components/catalog-header.component';
  import CatalogProductListComponent from '@/entities/catalog/components/catalog-product-list.component';
  import ProductsApi from '@/entities/catalog/api/products.api';
  import WishlistService from '@/store/wishlist';
  import { AuthVariableMixin, FollowMixin } from '@/plugins/mixin';
  import IntersectObserver from '@/shared/components/intersect-observer.vue';
  import normalizeProductCategory from '@/shared/helpers/normalize-product-category';
  import CatalogSortDropdown from '@/entities/catalog/components/catalog-sort-dropdown.component.vue';
  import { productSortItems } from '@/config/product-sort.config';

  export default {
    name: 'CatalogContainer',
    components: {
      CatalogSortDropdown,
      IntersectObserver,
      CatalogProductListComponent,
      CatalogHeaderComponent,
      CatalogBreadcrumbsComponent,
      CatalogNavComponent,
      CatalogContentComponent,
    },
    data() {
      return {
        metadata: {},
        productList: [],
        paginationInfo: {
          total: -1,
          offset: 0,
        },
        selectedCategory: 'Home',
        selectedSort: '',
        areProductsLoading: false,
        isLastResponseEmpty: false,
        error: null,
        productSortItems,
      };
    },
    mixins: [FollowMixin, AuthVariableMixin],
    computed: {
      ...mapGetters({
        head: 'catalogProducts/head',
        breadcrumbs: 'catalogProducts/breadcrumbs',
        breadcrumbList: 'catalogProducts/breadcrumbList',
        title: 'catalogProducts/title',
        navigation: 'catalogProducts/navigation',
        products: 'catalogProducts/products',
        getList: 'wishlist/getList',
      }),
      currentProductNumber() {
        return this.head?.n_products || 0;
      },
      finalNavigation() {
        return this.navigation || [];
      },
      isZeroLevel() {
        return this.finalBreadcrumbs.length <= 2;
      },
      label() {
        return this.title?.label || 'Catalog';
      },
      finalBreadcrumbs() {
        const breadcrumbs = [
          { title: 'Explore', link: '/' },
          { title: 'Catalog', link: '/catalog' },
          ...(this.breadcrumbList || []),
        ];

        if (this.title?.label) {
          breadcrumbs.push({
            title: this.title?.label,
          })
        }

        return breadcrumbs;
      },
      canLoadMoreProducts() {
        const { total, offset } = this.paginationInfo;
        return !((total !== -1) && (offset >= total));
      },
    },
    watch: {
      '$route'(route) {
        const category = route.params?.pathMatch;
        const sort = route.query?.sort;
        this.loadProducts(category, sort);
      },
      head: function(head, prevHead) {
        const metadata = normaliseMetaTags(head);
        const isCatalogOfZeroLevel = this.$route.path.match(/^\/catalog$/);
        if (isCatalogOfZeroLevel) {
          metadata.title = 'Catalog';
        }
        this.metadata = metadata;
        if (head?.id !== prevHead?.id) {
          this.$stats.send('page_opened', {
            page_type: 'catalog',
          }, { item: head });
        }
      },
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.toggleLoading(true);
        vm.getCatalogConfig({ url: to.params.pathMatch, query: to.query?.sort });
        vm.scrollToTop();
      });
    },
    beforeRouteUpdate(to, from, next) {
      this.toggleLoading(true);
      this.getCatalogConfig({ url: to.params.pathMatch, query: to.query?.sort });
      this.scrollToTop();
      next();
    },
    beforeMount() {
      this.scrollToTop();
      this.loadProducts(this.$route.params.pathMatch || '', this.$route.query.sort || '');
    },
    methods: {
      ...mapActions({
        getCatalogConfig: 'catalogProducts/getCatalogConfig',
        toggleLoading: 'catalogProducts/toggleLoading',
      }),
      scrollToTop() {
        this.$bus.$emit('HideHeader', false);
        setTimeout(() => {
          window.scrollTo({ top: 0 });
          document.getElementById('app-content')?.scrollIntoView({ behavior: 'smooth' });
          this.$bus.$emit('HideHeader', true);
        }, 300)
      },
      sortSelect(item) {
        this.$router.push({ query: item ? { sort: item } : null });
      },
      async loadProducts(category = '', sort = '') {
        const normalizedCategory = normalizeProductCategory(category);
        if (this.areProductsLoading) {
          return;
        }

        this.recalculateOffset(normalizedCategory, sort);
        if (!this.canLoadMoreProducts) {
          return;
        }

        try {
          this.areProductsLoading = true;
          const res = await ProductsApi.loadProducts({
            category: normalizedCategory,
            offset: this.paginationInfo.offset,
            sort,
          });
          const items = !normalizedCategory ? res.items : res.products;
          this.productList = [
            ...(this.paginationInfo.offset > 0 ? this.productList : []),
            ...items,
          ];
          this.paginationInfo = {
            total: normalizedCategory ? res.head.n_products : res.head.total,
            offset: res.head.start,
          }
        } catch (err) {
          console.log('err', err);
          this.paginationInfo = {
            ...this.paginationInfo,
            total: this.paginationInfo.total === -1 ? 0 : this.paginationInfo.total,
          }
          this.error = err;
        } finally {
          this.areProductsLoading = false;
        }
      },
      recalculateOffset(category = '', sort = '') {
        const s = category.toLowerCase().trim();
        const isSameSearch = this.selectedCategory === s;
        const newSort = sort.toLowerCase().trim();
        const isSameSort = this.selectedSort === newSort;

        this.selectedCategory = s;
        this.selectedSort = newSort;

        this.paginationInfo = {
          offset: isSameSearch && isSameSort ? this.productList.length : 0,
          total: !isSameSearch || !isSameSort ? -1 : this.paginationInfo.total,
        }
      },
      async onSetWish(item) {
        const isInWishList = Object.keys(this.getList || {}).includes(item.id);
        if (isInWishList) {
          await WishlistService.removeFromWishlist(item.id);

          this.$stats.send('remove_wishlist', {}, { item });

          this.sendingToWishlist = false;

          return;
        }

        this.$stats.send('add_wishlist', {}, { item });

        await WishlistService.addToWishlist(item.id);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .page {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 1420px;
    min-height: calc(100vh - 72px);
    margin: auto;
    padding: 10px 20px 20px 20px;

    @media (max-width: 800px) {
      min-height: auto;
    }

    .spinner-border {
      position: absolute;
      top: calc(50% - 23px);
      left: calc(50% - 23px);
    }
  }

  .page-container {
    width: 100%;
    max-width: 100%;
    &-breadcrumbs {
      margin-bottom: 16px;
      @media (max-width: 800px) {
        margin-bottom: 8px;
      }
    }
  }

  .page-container-content {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    max-width: 100%;
    @media (max-width: 800px) {
      display: block;
    }
  }

  .page-catalog {
    //display: none;
    max-width: 220px;
    width: 30%;
    //flex-basis: 349px;
    //max-width: 349px;

    @media (max-width: 800px) {
      display: block;
      width: 100%;
      max-width: 100%;
    }
  }

  .page-content {
    flex: 1;
    padding: 10px 20px 20px;
    overflow: hidden;
    &--header {
      display: none;
    }
    @media (max-width: 800px) {
      padding: 0;
      &--header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
      }
      &--mobile {
        display: none;
      }
    }
  }

</style>
